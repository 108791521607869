window.onload = function() {
    var popupContainer = document.querySelector('.popup-container');
    var popup1 = document.getElementById('popup1');
    var closeButtons = document.getElementsByClassName('close');

    popupContainer.style.display = "flex";
    popup1.style.display = "flex";

    // Close the corresponding popup when its 'x' is clicked
    for (var i = 0; i < closeButtons.length; i++) {
        closeButtons[i].onclick = function() {
            var popup = this.closest(".popup");
            popup.style.display = "none";
            checkPopups();
        }
    }

    // Close the popup when the user clicks outside of it
    window.onclick = function(event) {
        if (event.target == popup1) {
            event.target.style.display = "none";
            checkPopups();
        }
    }

    // Function to check the state of popups and hide the container if both are closed
    function checkPopups() {
        if (popup1.style.display === "none") {
            popupContainer.style.display = "none";
        }
    }
}




$(function(){
    $("#header").load("Header.html", function(){
        setActiveHeader();

        $(".mobile-toggler").click(function(){
            var menu = $(".mobile-menu");
            if(menu.css("display") == "none")
                menu.slideDown();
            else
                menu.slideUp();
        });
    });

    $(".mission-info").bind("click", showMission);

    $(".program-info-clickable").bind("click", showProgram);

    $("#footer").load("footer.html");
});

function setActiveHeader(){
    var path = window.location.pathname;
    if(path.indexOf("WhoWeAre") != -1)
        $(".who-menu-item").addClass('active');
    else if(path.indexOf("GetInvolved") != -1)
        $(".involved-menu-item").addClass('active');
    else if(path.indexOf("sponsorship") != -1)
        $(".sponsorship-menu-item").addClass('active');
    else if(path.indexOf("Events") != -1)
        $(".events-menu-item").addClass('active');
    else if(path.indexOf("Contact") != -1)
        $(".contact-menu-item").addClass('active');
    else
        $(".home-menu-item").addClass('active');
}

function showMission(){
    $('.programs-info-details').slideUp();
    $('.pid-mobile').slideUp();
    $(".arrow-up-teal").fadeOut();
    var oldText = $('.mission-goals-info').text() != "" ? $('.mission-goals-info').text() : $('.mission-info-mobile').text();
    var missionText = $(this).children('h6').text();
    var text = "";
    switch (missionText) {
        case "Prevention":
            text = "Support of the Precautionary Principle and Prevention is the Cure; encouragement and education about a healthy lifestyle.";
            break;
        case "Research":
            text = "To promote legislation to increase funding for environmental research and provide funding for breast and gynecological cancer projects. ";
            break;
        case "Education":
            text = "Providing up-to-date information through our newsletter and website. We offer adult and student environmental awareness programs designed to help participants reduce their exposure to toxic chemicals.";
            break;
        case "Services":
            text = "To offer breast and gynecological cancer patients a wealth of services paid for and facilitated by the Babylon Breast Cancer Coalition; such as the L.A.H.H, S.O.S and the Pink/Teal Ribbon Programs.";
            break;
        case "Support":
            text = "To provide support and referral services to breast and gynecological cancer patients and their families."
            break;
    }
    var isMobile = window.matchMedia("screen and (max-width: 991px)");

    var isOpen = false;
    if($(".mission-goals-info").css("display") != "none" || ($(".mission-info-mobile").css("display") != "none" && $(".mission-info-mobile").css("display") != undefined))
        isOpen = true;

    if(oldText == text){
        $(".mission-goals-info").slideUp();
        $(".mission-info-mobile").slideUp();
        $(".mission-goals-info").empty();
        $(".mission-info-mobile").empty();
        $(".mission-goal-info-wrapper").fadeOut(function(){
           $(this).remove();
        });
    }
    else if(isMobile.matches){
        var $that = $(this);
        $(".mission-info-mobile").slideUp();
        $(".mission-goal-info-wrapper").fadeOut(function(){
            $(this).remove();
        });
        $that.append(`
            <div class="relative full-width mt-50 mission-goal-info-wrapper">
                <div class='arrow-up-pink'></div>
                <div class='mission-info-mobile' style='display:none;'></div>
            </div>
        `);
        $(this).find('.arrow-up-pink').fadeIn();
        // $that.append("<div class='relative hidden full-width'><div class='arrow-up-pink></div>");
        // $that.append("<div class='mission-info-mobile' style='display:none;'></div>");
        $(".mission-info-mobile").text(text).slideDown();

    }
    else{
        var left = $(this).offset().left - $(this).parent().offset().left - 35;
        $(".arrow-up-pink").fadeOut();
        $(".mission-goals-info").fadeOut(function(){
            $(".mission-goals-info").parent().show();
            $(".arrow-up-pink").fadeIn();
            $(".mission-goals-info").text(text);
            $(".mission-goals-info").append("<div class='arrow-up-pink'></div>");
            $(".arrow-up-pink").css("left", left);
            $(".mission-goals-info").slideDown();
        });

    }
}

function showProgram(){
    $('.mission-goals-info').slideUp();
    $('.mission-info-mobile').slideUp();
    $(".mission-goal-info-wrapper").fadeOut(function(){
        $(this).remove();
    });
    var oldText = $('.programs-info-details').text() != "" ? $('.programs-info-details').text() : $('.pid-mobile').text();
    var programText = $(this).index('.program-info-clickable');
    var text = "";
    switch (programText) {
        case 0:
            text = `<p>
            Since its inception in 1996, this unique patient service program provides much needed stress relief for 
            breast cancer patients currently in treatment and their families confronting diagnosis and ensuing treatments, 
            such as chemotherapy and radiation. In spring 2007, the BBCC expanded the LAHH program to include Babylon Township 
            women undergoing treatment for all types of gynecological cancers.
            </p>
            <p>
            • Services offered: house cleaning, transportation to/from medical appointments, financial assistance, child care, massage therapy, meal preparation and more.
            </p>
            <p>
            <a href='/Img/Pdf/2018_Intro_and%20Application%20_Supplemental%20Demographics%20for%20distribution.pdf' target="_blank">LAHH Application >> </a> 
            </p>
            <p>
            <br/>
            In 2004, the BBCC introduced the SOS, a program dedicated to help those who have lost a loved one residing 
            in Babylon Township to breast or gynecological cancer. SOS offers services such as financial assistance with 
            funeral expenses, child care expenses, prepared food, and house cleaning.
            </p>
            <p>
            <a href='/Img/Pdf/2018_SOS_Questionnaire.pdf' target="_blank">SOS Questionnaire >></a>
            </p>`;
            break;
        case 1:
            text = `<p> The Gift of Health and Inspiration (G.O.H.I) includes a free tote bag which is designed to be a supportive 
            companion to the newly diagnosed cancer patient. The tote bag is filled with items to assist with your mind, body and 
            soul. Our items are nurturing, organic and holistic. 
            </p>
            <p>
            <a href='/Img/Pdf/GOHI_Personal Tracker.pdf' target="_blank">GOHI Personal Tracker >> </a> 
            </p>`;
            break;
        case 2:
            text = `<p>
            A beautiful contemplative space with lovely landscaping, sculptures, benches and a pink ribbon-shaped path paved with engraved red bricks in memory of or in honor of someone who has battled breast cancer. A connector pathway which connects the pink ribbon path and the newly expanded O-shaped path paved with engraved grey bricks in memory of or in honor of someone who has battled gynecological cancer. The garden is located on the southeast corner of Babylon Town Hall in Lindenhurst. Bricks are available for engraving and installation at a cost of $50 each.
            </p>
            <p>
            <a href="/Img/Pdf/Vision of Hope BRICKS order form 2020.pdf" target="_blank">VOHG Brick Order Form >></a>
            </p>
            <p>
            <a href="/Img/Pdf/VisionHopeRIBBONS order form 2020.pdf" target="_blank">VOHG Ribbon Order Form >></a>
            </p>`;
            break;
        case 3:
            text = "Created in 2007, Be Smart About Your Health is a program of various in-school workshops designed for students emphasizing the possible link between our environment and personal health. The workshops empower students to be more conscious about healthier practices and products. Presentations are available for large and small groups, from kindergarten through high school. ";
            break;
        case 4:
            text = "This exercise program is designed to help women regain flexibility, stamina and strength following breast or gynecological surgery and/or cancer treatment. Clients meet with trained exercise physiologists for one-on-one personal training sessions to address their specific physical goals and issues."
            break;
        case 5:
            text = `<p>
            The BBCC offers scholarships to Babylon Township high school seniors who have had a parent or legal guardian with a personal breast and/or gynecological cancer history. 
            </p>
            <p>
                <span onclick="showScholarRecips()" style="cursor: pointer"><u>Scholarship Recipients</u></span>
            </p>
            <p>
            <a href='/Img/Programs/BBCC_scholarship_application_2025.pdf' target="_blank">BBCC Scholarship Form >> </a> 
            </p>`;
            break;
    }
    var isMobile = window.matchMedia("screen and (max-width: 991px)");

    var isOpen = false;
    if($(".mission-goals-info").css("display") != "none" || ($(".mission-info-mobile").css("display") != "none" && $(".mission-info-mobile").css("display") != undefined))
        isOpen = true;

    if(oldText.indexOf(text.replace(/(<([^>]+)>)/ig,"")) != -1 ){
        $(".programs-info-details").slideUp();
        $(".pid-mobile").slideUp();
        $(".arrow-up-teal").fadeOut();
        $(".programs-info-details").empty();
        $(".pid-mobile").empty();
    }
    else if(isMobile.matches){
        var program = "pidm-1";
        var $that = $(this);
        if(programText >= 2 && programText < 4) {
            program = "pidm-2";
            $(".pidm-1").slideUp();
            $(".pidm-3").slideUp();
            $(".arrow-up-teal").fadeOut();
            $(".pidm-2").fadeOut(function(){
                $(".programs-info-details").empty();
                $("." + program).html(text);
                var left = ($that.offset().left - $that.parent().offset().left) + ($that.width() / 2.25);
                $(".arrow-" + program).css("left", left);
                $("." + program).parent().show();
                $(".arrow-" + program).fadeIn();
                $("." + program).slideDown();
            });
        }
        else if(programText >= 4) {
            program = "pidm-3";
            $(".pidm-1").slideUp();
            $(".pidm-2").slideUp();
            $(".arrow-up-teal").fadeOut();
            $(".pidm-3").fadeOut(function(){
                $(".programs-info-details").empty();
                $("." + program).html(text);
                var left = ($that.offset().left - $that.parent().offset().left) + ($that.width() / 2.25);
                $(".arrow-" + program).css("left", left);
                $("." + program).parent().show();
                $(".arrow-" + program).fadeIn();
                $("." + program).slideDown();
            });
        }
        else{
            $(".pidm-2").slideUp();
            $(".pidm-3").slideUp();
            $(".arrow-up-teal").fadeOut();
            $(".pidm-1").fadeOut(function(){
                $(".programs-info-details").empty();
                $("." + program).html(text);
                var left = ($that.offset().left - $that.parent().offset().left) + ($that.width() / 2.25);
                $(".arrow-" + program).css("left", left);
                $("." + program).parent().show();
                $(".arrow-" + program).fadeIn();
                $("." + program).slideDown();
            });
        }
    }
    else{
        var program = "pid-1";
        var $that = $(this);
        if(programText >= 3 ) {
            program = "pid-2";
            $(".pid-1").slideUp();
            $(".arrow-up-teal").fadeOut();
            $(".pid-2").fadeOut(function(){
                $(".programs-info-details").empty();
                $("." + program).html(text);
                var left = ($that.offset().left - $that.parent().offset().left) + ($that.width() / 2.25);
                $(".arrow-" + program).css("left", left);
                $("." + program).parent().show();
                $(".arrow-" + program).fadeIn();
                $("." + program).slideDown();
            });
        }
        else {
            $(".pid-2").slideUp();
            $(".arrow-up-teal").fadeOut();
            $(".pid-1").fadeOut(function(){
                $(".programs-info-details").empty();
                $("." + program).html(text);
                var left = ($that.offset().left - $that.parent().offset().left) + ($that.width() / 2.25);
                $(".arrow-" + program).css("left", left);
                $("." + program).parent().show();
                $(".arrow-" + program).fadeIn();
                $("." + program).slideDown();
            });
        }
    }
}

function showScholarRecips(){
    var element = document.getElementById('Scholars');
    element.style.display = "block";
}
function hideScholarRecips(){
    var element = document.getElementById('Scholars')
    element.style.display = "none"
}

function recaptchaCallback() {
    $('.contact-form-submit-btn').removeAttr('disabled');
    $('.g-recaptcha').val(grecaptcha.getResponse())
}

function createEmail(kit){
    $(".contact-form-submit-btn").prop('disabled', true);
    var data = Object.fromEntries(new FormData($('#contactForm')[0]));
    grecaptcha.reset();

    $.post('/api/Contact/CreateForm/', data)
        .done((response) => {
            if (response.success) {
                var responseElement = $('#formResponse').removeClass("alert-danger").addClass("alert-success").text("Thank you for contacting us!").css({ 'display': 'block' });
                $('#ContactFormWrapper').html(responseElement).css({ 'display': 'flex', 'justify-content': 'center' });
            } else {
                var message = 'There was an internal server error while submitting this form. We apologize for the inconvenience.';
                if (response.error == 'invalid-input-response') {
                    message = "ReCaptcha challenge failed. Please complete the ReCaptcha and try again.";
                }

                $('#formResponse').addClass("alert-danger").text(message).show();
            }
        })
        .fail((e) => {
            $('#formResponse').addClass("alert-danger").text('There was an error submitting this form. We apologize for the inconvenience.').show();
        })
}
